var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advisor-form"
  }, [_c('div', {
    staticClass: "advisor-form__actions"
  }, [_c('GenproxButton', {
    attrs: {
      "outline": true,
      "icon": _vm.ChevronLeftIcon
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 1), _c('div', {
    staticClass: "advisor-form__box"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "advisor-form__content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5"
  }, [_vm._v("Configure advisor form")]), _vm._l(_vm.groups, function (group) {
    return _c('div', {
      key: group.label,
      staticClass: "advisor-form__group"
    }, [_c('p', {
      staticClass: "title mb-3 mt-3"
    }, [_vm._v(_vm._s(group.label))]), _c('table', {
      staticClass: "advisor-form__table mb-5"
    }, [_vm._m(0, true), _c('tbody', [_vm._l(group.items, function (option) {
      return _c('tr', {
        key: option
      }, [_c('td', [_c('p', {
        staticClass: "label"
      }, [_vm._v(_vm._s(option.replace(/^_*(.)|_+(.)/g, function (el, c, d) {
        return c ? c.toUpperCase() : ' ' + d.toUpperCase();
      })))])]), _c('td', [_c('sygni-toggle-switch', {
        attrs: {
          "disabled": _vm.requiredOptions.includes(option) || _vm.isMarketplaceAccountant
        },
        model: {
          value: _vm.getOption(option).visible,
          callback: function callback($$v) {
            _vm.$set(_vm.getOption(option), "visible", $$v);
          },
          expression: "getOption(option).visible"
        }
      })], 1), _c('td', [_c('sygni-toggle-switch', {
        attrs: {
          "disabled": _vm.requiredOptions.includes(option) || !_vm.getOption(option).visible || _vm.isMarketplaceAccountant
        },
        model: {
          value: _vm.getOption(option).required,
          callback: function callback($$v) {
            _vm.$set(_vm.getOption(option), "required", $$v);
          },
          expression: "getOption(option).required"
        }
      })], 1)]);
    }), group.label === 'Required data' ? _c('tr', [_vm._m(1, true), _c('td', [_c('sygni-toggle-switch', {
      attrs: {
        "value": true,
        "disabled": true
      }
    })], 1), _c('td', [_c('sygni-toggle-switch', {
      attrs: {
        "value": true,
        "disabled": true
      }
    })], 1)]) : _vm._e()], 2)])]);
  }), !_vm.isMarketplaceAccountant ? _c('GenproxButton', {
    staticClass: "mt-5",
    on: {
      "click": function click($event) {
        return _vm.saveChanges();
      }
    }
  }, [_vm._v(_vm._s(_vm.hasAdvisorForm ? 'Save changes' : 'Save'))]) : _vm._e(), _c('div', {
    attrs: {
      "clas": "router-link"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button mt-4"
  }, [_vm._v("Cancel")])])], 2)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field name")]), _c('th', [_vm._v("Display")]), _c('th', [_vm._v("Require")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('p', {
    staticClass: "label"
  }, [_vm._v("Permissions")])]);
}]

export { render, staticRenderFns }