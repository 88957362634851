
























































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {mapState} from "vuex";
import { Watch } from 'vue-property-decorator';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniRectButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, SygniLoader, GenproxButton, ChevronLeftIcon },
  computed: {
    ...mapState('advisors', {
      advisorFormFields: (state: any) => state.advisorForm.fields,
    })
  }
})
export default class AdvisorFormModule extends Vue {
  ChevronLeftIcon = ChevronLeftIcon;
  hasAdvisorForm: boolean = false;
  isLoading: boolean = false;
  requiredOptions: string[] = [
    'first_name',
    'surname',
    'email'
  ]
  values: any = null;
  advisorFormGroups: any = [
    {
      label: 'Required data',
      fields: ['first_name', 'surname', 'email'],
      items: [],
    },
    {
      label: 'Additional data',
      fields: ['identification_number', 'external_id', 'phone_number', 'middle_name', 'document_type', 'document_number', 'document_expiry_date', 'document_country', 'start_power_of_attorney', 'end_power_of_attorney'],
      items: [],
    },
  ]
  
  get options() {
    return this.$store.getters['advisors/advisorFormFields'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get groups() {
    const groups = this.advisorFormGroups;

    this.options.forEach((option: any) => {
      const group = this.advisorFormGroups.find((el: any) => el.fields.includes(option));

      if(group) {
        group.items.push(option);
      } else {
        this.advisorFormGroups[0].items.push(option);
      }
    })

    return groups;
  }

  getOption(name: string) {
    return this.values?.find((el: any) => el.name === name);
  }

  get advisorFormItems() {
    return this.$store.getters['advisors/advisorFormData']?.parameters?.items ? this.$store.getters['advisors/advisorFormData'].parameters.items : [];
  }

  async saveChanges() {
    this.isLoading = true;
    try {
      const payload = this.values.filter((el: any) => el.visible).map((el: any) => {
        return { name: el.name, required: el.required };
      });

      await this.$store.dispatch('advisors/postAdvisorForm', payload);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Advisor form saved.'
      });

      if (!this.hasAdvisorForm) {
        this.$router.go(-1)
      }

    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isLoading = false;
  }

  loadOptions() {
    this.values = this.options.map((el: string) => {
      const option = this.advisorFormItems?.find((item: any) => item.name === el);
      return {
        name: el,
        visible: this.requiredOptions.includes(el) ? true : option ? true : false,
        required: option ? option.required : true,
      }
    })
  }

  @Watch('options') onOptionsChange() {
    this.loadOptions();
  }

  async mounted() {
    try {
      await this.$store.dispatch('advisors/getAdvisorForm');
      this.hasAdvisorForm = true;
    } catch(e) {
      this.hasAdvisorForm = false;
      this.$notify({
        type: 'error',
        title: 'Action required',
        text: 'Configure your advisor form'
      })
      e;
    }
    this.loadOptions();
  }
}

